import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <div className="mb-5">
        <Container className="mt-5 pt-5">
          <Row className="justify-content-center">
            <Col lg={10} md={12} className="privacy-policy-content">
              <h1>Privacy Policy</h1>

              <section className="mt-4">
                <h5 className="fw-bold">CUSTOMER DATA.</h5>
                <p>
                  You retain rights you have in any data, information, or material that you submit to Khet-E in the course of using the Software Services (the “Customer Data”). You, and not Khet-E, shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all Customer Data will belongs to Khet-E, and Khet-E shall not be responsible or liable for the deletion, correction, destruction, damage, loss, or failure to store any Customer Data. In the event this Software Agreement or a subscription to Software Services is terminated (other than by reason of your breach), you shall have thirty (30) days to access the Software Services terminated solely to export their related Customer Data. Upon termination for breach, your access or use to the Customer Data immediately ceases, and Khet-E shall have no obligation to maintain, provide, or provide access to any Customer Data.
                </p>
                <p>
                  You represent and warrant to Khet-E that Customer Data: (a) is original to you or that you have secured the rights to provide and use such Customer Data; (b) does not contain any content that is unlawful, infringes or misappropriates the Intellectual Property of others, or is invasive of another’s privacy or publicity rights; and (c) does not contain a virus or other harmful component. Khet-E ’s use of the Customer Data is subject to Khet-E ’s privacy policies and the terms of this Software Agreement.
                </p>
                <p>
                  Khet-E shall possess the rights to (a) disclose, provide, or make available the Customer Data to a third party without Customer’s prior written approval; or (b) make any other use of the Customer Data. Customer acknowledges that Khet-E may compile certain general information related to the use of the Software Services.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">MONITORING OF USE AND ADDITIONAL RESTRICTIONS.</h5>
                <p>
                  You acknowledge and agree that Khet-E reserves the right to remotely prevent access to and/or use of the Software Services, with or without notice to you, including without limitation in the event that:
                </p>
                <p>(i) Khet-E becomes aware, from you or otherwise, of unauthorized access or use of the Software Services by any third party using any user name, password, or other login credentials of you, or in the event of a security concern related to the Software Services, or</p>
                <p>(ii) your violation of any term or condition of this Software Agreement.</p>
                <p>
                  Khet-E reserves the right, but does not have the obligation, to monitor use of Software Services to determine compliance with this Software Agreement.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">ADDITIONAL REPRESENTATIONS BY YOU.</h5>
                <p>
                  You represent and warrant that:
                </p>
                <p>(a) you are the owner or an authorized user of the Device on which the Software Services are to be accessed; and</p>
                <p>(b) you shall use the Software Services only for lawful purposes, and will comply at all times with all applicable state, and local laws and regulations applicable to the use of the same.</p>
                <p>The application will install double click dart cookies in the device for the additional advertisement and promotional activities.</p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">PROPRIETARY NOTICES.</h5>
                <p>
                  You agree to maintain and reproduce all copyright, patent, trademark and other proprietary notices on all copies, in any form, of the Software Services and its Content, in the same form and manner that such copyright and other proprietary notices are included on the Software Services, whether they are Khet-E notices or those of third parties.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">CONFIDENTIAL INFORMATION.</h5>
                <p>
                  The Software Services, including, but not limited to, source and object code, logic and structure, database structure, regulatory compilations, and any and all copies of the foregoing, regardless of the form or media in or on which any of them may exist (all together, the “Confidential Information”) constitute valuable trade secrets, are the Intellectual Property and confidential information of Khet-E.
                </p>
                <p>Confidential Information additionally includes non-public information disclosed by Khet-E if it is clearly and conspicuously marked as “confidential” or with a similar designation at the time of disclosure...</p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">MAINTENANCE AND UPGRADES.</h5>
                <p>
                  You acknowledge that the Software Services licenses are granted separately from any subscription to future upgrades, updates and fixes to it, unless specifically stated in relation to the particular license.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">DEVICE RESPONSIBILITY.</h5>
                <p>
                  In order to use the Software Services, you must provide all Devices, equipment and software necessary to use the Software Services, including, but not limited to, a suitably fast Internet connection and a Device that is in working order running an operating system and software compatible with the Software Services.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">TERMINATION.</h5>
                <p>
                  In addition to any other termination rights set forth in this Software Agreement, the licenses granted by Khet-E under this Software Agreement terminate at the end of their designated subscription term if not renewed.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">FEEDBACK.</h5>
                <p>
                  You may provide feedback to Khet-E with respect to the Software Services.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">WARRANTY AND WARRANTY DISCLAIMER.</h5>
                <p>
                  Khet-E represents and warrants that it has the power and authority to enter into this Software Agreement and to perform all of its obligations.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">LIMITATION ON LIABILITY.</h5>
                <p>
                  To the fullest extent allowed by law, neither Khet-E nor its subsidiaries, affiliates, licensors, service providers, employees, or agents will be liable for any incidental, indirect, punitive, or consequential damages.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">INDEMNITY.</h5>
                <p>
                  Khet-E shall indemnify, defend, and hold harmless you and your subsidiaries, affiliates, and their respective owners and employees from any and all final, non-appealable judgments.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">TRADEMARKS AND COPYRIGHTS.</h5>
                <p>
                  Trademarks, service marks, logos, and copyrighted works appearing in the Software Services are the property of Khet-E.
                </p>
              </section>

              <section className="mt-4">
                <h5 className="fw-bold">MISCELLANEOUS.</h5>
                <p>FORCE MAJEURE: Neither party shall be liable for any failure or delay in fulfilling this Software Agreement due to circumstances beyond the reasonable control of the impacted party.</p>
              </section>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
