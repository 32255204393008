import React from "react";
import { Carousel, Card, Button, Container, Row, Col } from "react-bootstrap";
import image1 from "../assets/images/home page 3 animation images (1440 x 700)/Khet-e Consulting.png";
import image2 from "../assets/images/home page 3 animation images (1440 x 700)/Khet-e Training.png";
import image3 from "../assets/images/home page 3 animation images (1440 x 700)/Sell your Produce.png";
import cornerImage from "../assets/images/main-left-img.png";
import "../assets/css/header.css";

function Header() {
  return (
    <>
      <div>
        <Container fluid className="mt-1 main-container">
          <Row className="mobile-view-flow">
            <Col lg={2} md={3} sm={12} className="d-none d-lg-block">
              {/* Left Image */}
              <img
                className="corner-image"
                src={cornerImage}
                alt="Corner Image"
              />
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Carousel
                interval={3000}
                controls={true}
                indicators={true}
                pause="hover"
                className="header-carousel"
              >
                
                <Carousel.Item>
                  <img
                    className="d-block main-container-image"
                    src={image1}
                    alt="First slide"
                  />
                  <div className="carousel-caption d-none d-lg-flex justify-content-center align-items-center card-mobile-view">
                    <Card className="text-left card-overlay">
                      <Card.Body>
                        <Card.Title className="text-uppercase card-title text-start">
                          Khet-E <br />{" "}
                          <span className="card-heading text-uppercase">Consulting</span>
                        </Card.Title>
                        <Card.Text className="text-start ">
                          The digital revolution in agriculture is transforming
                          farming operations. Technology integration and expert
                          consulting can enhance operations by providing data on
                          crop health, soil conditions, and water usage,
                          optimizing fertilizer and pesticide applications, and
                          enhancing crop management strategies.
                        </Card.Text>
                        <Button
                          variant=""
                          className="explore-button mt-3 d-flex justify-content-center align-items-center"
                        >
                          EXPLORE
                        </Button>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="d-flex d-lg-none flex-column align-items-center mt-3">
                    <Card className="text-left main-slider-crd">
                      <Card.Body className="main-card-body">
                        <Card.Title className="text-uppercase card-title text-start">
                          Khet-E <br />{" "}
                          <span className="card-heading">Consulting</span>
                        </Card.Title>
                        <Card.Text className="text-start ">
                          The digital revolution in agriculture is transforming
                          farming operations. Technology integration and expert
                          consulting can enhance operations by providing data on
                          crop health, soil conditions, and water usage,
                          optimizing fertilizer and pesticide applications, and
                          enhancing crop management strategies.
                        </Card.Text>
                        <Button
                          variant=""
                          className="explore-button mt-4 d-flex justify-content-center align-items-center"
                        >
                          EXPLORE
                        </Button>
                      </Card.Body>
                    </Card>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="d-block  main-container-image"
                    src={image2}
                    alt="Second slide"
                  />
                  <div className="carousel-caption d-none d-lg-flex justify-content-center align-items-center h-100 card-mobile-view">
                    <Card className="text-left card-overlay">
                      <Card.Body>
                        <Card.Title className="text-uppercase card-title text-start">
                          Khet-E <br />{" "}
                          <span className="card-heading text-uppercase">Training</span>
                        </Card.Title>
                        <Card.Text className="text-start">
                          We empower farmers with knowledge of modern
                          techniques, from sustainable practices to technology
                          integration. We do a lot of innovation, boost
                          productivity, and ensures optimal resource
                          utilization. By joining us, you can contribute to
                          building a revised agricultural sector. Join our
                          trainings and achieve growth.{" "}
                        </Card.Text>
                        <Button
                          variant=""
                          className="explore-button mt-3 d-flex justify-content-center align-items-center "
                        >
                          EXPLORE
                        </Button>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="d-flex d-lg-none flex-column align-items-center mt-3">
                    <Card className="text-left main-slider-crd">
                      <Card.Body className="main-card-body">
                        <Card.Title className="text-uppercase card-title text-start">
                          Khet-E <br />{" "}
                          <span className="card-heading">Training</span>
                        </Card.Title>
                        <Card.Text className="text-start ">
                          We empower farmers with knowledge of modern
                          techniques, from sustainable practices to technology
                          integration. We do a lot of innovation, boost
                          productivity, and ensures optimal resource
                          utilization. By joining us, you can contribute to
                          building a revised agricultural sector. Join our
                          trainings and achieve growth.{" "}
                        </Card.Text>
                        <Button
                          variant=""
                          className="explore-button mt-4 d-flex justify-content-center align-items-center "
                        >
                          EXPLORE
                        </Button>
                      </Card.Body>
                    </Card>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="d-block  main-container-image"
                    src={image3}
                    alt="Third slide"
                  />
                  <div className="carousel-caption d-none d-lg-flex justify-content-center align-items-center h-100 card-mobile-view">
                    <Card className="text-left card-overlay">
                      <Card.Body>
                        <Card.Title className="text-uppercase card-title text-start">
                          Sell Your <br />{" "}
                          <span className="card-heading text-uppercase">Produce</span>
                        </Card.Title>
                        <Card.Text className="text-start ">
                          Our 'Sell Your Produce' subscription helps farmers
                          sell their produce at fair prices by providing
                          real-time market rates and connecting them directly
                          with potential buyers. This eliminates middlemen and
                          ensures better returns, enabling financial stability
                          and growth for farmers.{" "}
                        </Card.Text>
                        <Button
                          variant=""
                          className="explore-button mt-3 d-flex justify-content-center align-items-center"
                        >
                          EXPLORE
                        </Button>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="d-flex d-lg-none flex-column align-items-center mt-3">
                    <Card className="text-left main-slider-crd">
                      <Card.Body className="main-card-body">
                        <Card.Title className="text-uppercase card-title text-start">
                          Sell Your <br />{" "}
                          <span className="card-heading">Produce</span>
                        </Card.Title>
                        <Card.Text className="text-start ">
                          Our 'Sell Your Produce' subscription helps farmers
                          sell their produce at fair prices by providing
                          real-time market rates and connecting them directly
                          with potential buyers. This eliminates middlemen and
                          ensures better returns, enabling financial stability
                          and growth for farmers.{" "}
                        </Card.Text>
                        <Button
                          variant=""
                          className="explore-button mt-4 d-flex justify-content-center align-items-center"
                        >
                          EXPLORE
                        </Button>
                      </Card.Body>
                    </Card>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Header;
