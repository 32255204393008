import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';

const CallDoctorPage = () => {
  useEffect(() => {
    const openApp = () => {
      const now = new Date().valueOf();
      setTimeout(() => {
        if (new Date().valueOf() - now > 100) return;
        window.location = "https://play.google.com/store/apps/details?id=in.khete.kheteApp";
      }, 25);
      window.location = "intent://calldoctor#Intent;scheme=http;package=in.khete.kheteApp;end";
    };

    openApp();
  }, []);

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center min-vh-100">
      <Row className="text-center">
        <Col>
          <h1 className="mb-4">Call Doctor Page</h1>
          <Alert variant="success" className="mb-4">
            If you see this page, the deep link is working correctly.
          </Alert>
          <Button
            href="https://play.google.com/store/apps/details?id=in.khete.kheteApp"
            target="_blank"
            variant="primary"
            size="lg"
          >
            Download the App
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CallDoctorPage;
