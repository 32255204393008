import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function CancellationPolicy() {
  return (
    <>
      <Navbar />
      <div className="mb-5">
        <section id="policies" className="policies section">
          <Container className="mt-5">
            <Row>
              <Col xs={12}>
                <div className="policies-content">
                  <h2>Cancellation Policy</h2>
                  <p>
                    Khet-E doesn't give refunds for subscription purchases,
                    however can proceed with refunds for any third-party
                    purchases. However, there are exceptions detailed below. You
                    can also contact the customer care center number and email
                    ID provided in the application. The respective team can help
                    with purchase issues and can process refunds according to
                    their policies and applicable laws.
                  </p>
                  <h3>Refund policy</h3>
                  <ul>
                    <li>
                      <strong>Within 48 hours:</strong> You may be able to get a
                      refund depending on the details of the purchase.
                    </li>
                    <li>
                      <strong>After 48 hours:</strong> Contact the customer care
                      and service team to troubleshoot and find out if you can
                      get a refund. Most often, customer service teams have
                      their own policies and legal requirements and may be able
                      to give you a refund.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default CancellationPolicy;
