import React, { useState } from "react";
import axios from "axios";
import khetElogo from "../assets/images/logo.png";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASEURL;
const razorpayKey = process.env.RAZORPAY_KEY;

const loadRazorpayScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      console.log("Razorpay script loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.log("Failed to load Razorpay script");
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const RazorpayPayment = ({
  amount,
  description,
  buttonStyle,
  category,
  productId,
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({ mobile: "" });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile") {
      const mobileRegex = /^[0-9]{0,10}$/;
      if (!mobileRegex.test(value)) {
        setErrors({ ...errors, mobile: "Mobile number must be 10 digits" });
      } else {
        setErrors({ ...errors, mobile: "" });
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handlePayment = async () => {
    const { firstName, lastName, mobile } = formData;

    if (!firstName || !lastName || !mobile) {
      return;
    }

    if (mobile.length !== 10 || errors.mobile) {
      return;
    }

    const scriptLoaded = await loadRazorpayScript();

    if (!scriptLoaded) {
      alert(
        "Razorpay SDK failed to load. Please check your internet connection."
      );
      return;
    }

    let orderId;
    try {
      const createOrderResponse = await axios.post(
        `${baseUrl}/api/payment/adv-product/create`,
        {
          amount: amount,
          name: `${firstName} ${lastName}`,
          mobile: mobile,
          category: category,
          productId: productId,
        }
      );

      console.log("Order creation response:", createOrderResponse);

      if (
        createOrderResponse.data &&
        createOrderResponse.data.data &&
        createOrderResponse.data.data.id
      ) {
        orderId = createOrderResponse.data.data.id;
        console.log("Order ID:", orderId);
      } else {
        alert("Failed to create order. No orderId found in response.");
        return;
      }
    } catch (error) {
      alert("Error creating the order: " + error.message);
      return;
    }

    const options = {
      key: razorpayKey,
      amount: amount * 100,
      currency: "INR",
      name: `${firstName} ${lastName}`,
      image: khetElogo,
      order_id: orderId,
      handler: async function (response) {
        const { razorpay_payment_id } = response;

        console.log("Razorpay payment ID:", razorpay_payment_id);
        console.log("Razorpay order ID:", orderId);

        if (!razorpay_payment_id) {
          alert("Payment failed. No payment ID received.");
          return;
        }

        try {
          const capturePaymentResponse = await axios.post(
            `${baseUrl}/api/payment/adv-product/capture`,
            {
              paymentId: razorpay_payment_id,
              orderId: orderId,
            }
          );

          if (
            capturePaymentResponse.data &&
            capturePaymentResponse.data.statusCode === 200
          ) {
            console.log("Payment successful! Navigating to success page...");

            navigate("/payment-success", {
              state: {
                paymentId: razorpay_payment_id,
                orderId: capturePaymentResponse.data.data.orderId,
                amount: capturePaymentResponse.data.data.amount,
                product: capturePaymentResponse.data.data.productId,
              },
            });
          } else {
            alert("Failed to capture the payment. Please try again.");
          }
        } catch (error) {
          alert("Error capturing payment: " + error.message);
        }
        document.body.style.overflow = "auto"; 

      },
      modal: {
        ondismiss: function () {
          document.body.style.overflow = "auto"; 
        },
      },
      prefill: {
        name: `${firstName} ${lastName}`,
        contact: mobile || "9999999999",
      },
      theme: {
        color: "#3399cc",
      },
    };

    console.log("Razorpay options: ", options);

    const rzp = new window.Razorpay(options);
    rzp.open();
    handleClose(); 
    document.body.style.overflow = "hidden"; 
      };

  return (
    <>
      <Button
        className="buy-now-btn no-hover-effect"
        onClick={handleShow}
        style={{ ...buttonStyle }}
      >
        Buy Now
      </Button>

      <Modal show={showModal} onHide={handleClose} style={{fontFamily:'Inter'}}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Enter Your Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="payment-form" >
            <div className="mb-3">
              <label className="text-dark">
                First Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="text-dark">
                Last Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="text-dark">
                Mobile <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="mobile"
                className="form-control"
                value={formData.mobile}
                onChange={handleInputChange}
                required
              />
              {errors.mobile && (
                <small className="text-danger">{errors.mobile}</small>
              )}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePayment}>
            Continue to Payment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RazorpayPayment;
