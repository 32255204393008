// src/pages/HomePage.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeroSection from "../components/HeroSection";
import FeaturesSection from "../components/FeaturesSection";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Header from "../components/Header";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.refresh && !sessionStorage.getItem("homeRefreshed")) {
      window.location.reload();
      sessionStorage.setItem("homeRefreshed", "true");
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <Header/>
      {/* <HeroSection /> */}
      {/* <FeaturesSection /> */}
      {/* <AchievementSection /> */}
      {/* <PricingSection /> */}
      {/* <CallToAction /> */}
      <Footer />
    </>
  );
};

export default Home;
