import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FiMenu, FiSearch, FiX } from 'react-icons/fi';
import '../assets/css/main.css'; 
import khetElogo from '../assets/images/logo.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); 
  const location = useLocation(); 

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

   // Function to determine if "Products" link should be active
   const getNavLinkClass = (path) => {
    if (
      path === '/product-category' &&
      (location.pathname.startsWith('/product-category/') || location.pathname.startsWith('/products/products-detail/'))
    ) {
      return 'nav-link active';
    }
    return location.pathname === path ? 'nav-link active' : 'nav-link';
  };

  return (
    <header className="header navbar-area " style={{ backgroundColor: 'white', position: 'fixed', width: '100%' }}>
      <Container>
        <Row className="align-items-center">
          <Col lg={12}>
            <div className="nav-inner">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to="/">
                  <img src={khetElogo} alt="Logo" />
                </Link>
                <button
                  className="navbar-toggler mobile-menu-btn"
                  type="button"
                  onClick={toggleMenu} 
                >
                  {isMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />} 
                </button>
                <div className={`collapse navbar-collapse sub-menu-bar ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
                  <ul id="nav" className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <Link to="/" className={getNavLinkClass('/')} style={{ color: 'black' }}>Home</Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link to="" className={getNavLinkClass('/features')} style={{ color: 'black' }}>Features</Link>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link to="" className={getNavLinkClass('/overview')} style={{ color: 'black' }}>Overview</Link>
                    </li> */}
                    <li className="nav-item">
                      <Link to="/product-category" className={getNavLinkClass('/product-category')} style={{ color: 'black' }}>Services</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="" className={getNavLinkClass('/team')} style={{ color: 'black' }}>Blog</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="" className={getNavLinkClass('/team')} style={{ color: 'black' }}>Partners</Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link to="" className={getNavLinkClass('/blog')} style={{ color: 'black' }}>Blog</Link>
                    </li> */}
                    <li className="nav-item">
                      <Link to="" className={getNavLinkClass('/about')} style={{ color: 'black' }}>About Us</Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link to="" className={getNavLinkClass('/contact')} style={{ color: 'black' }}>Contact</Link>
                    </li> */}
                  </ul>
                </div>
                {/* <div className="button add-list-button">
                  <Link to="/get-it-now" className="btn button add-list-button">Get it now</Link>
                </div> */}
                <div className="search-icon d-none d-md-block" style={{ marginLeft: '15px' }}>
  <FiSearch size={22} style={{ cursor: 'pointer', color: 'black' }} />
</div>

              </nav>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Navbar;
