import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import Navbar from './components/Navbar';
import Home from "./pages/Home";
import CallDoctorPage from "./pages/CallDoctor";
import ProductCategory from "./pages/ProductCategory";
import Products from "./pages/Products";
import ProductDetail from "./pages/ProductDetail";
import PaymentSuccess from "./pages/PaymentSuccess";
import ScrollToTop from "./common/ScrollToTop";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition";
import CancellationPolicy from"./pages/CancellationPolicy";

function App() {
  return (
    <Router>
      <ScrollToTop/> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/callDoctor" element={<CallDoctorPage />} />
        <Route path="/product-category" element={<ProductCategory />} />

        <Route path="/product-category/:categoryId/products" element={<Products />} />
        <Route path="/products/products-detail/:id" element={<ProductDetail />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/cancellation-policy" element={<CancellationPolicy />} />

      </Routes>
    </Router>
  );
}

export default App;
